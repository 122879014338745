<template>
    <div class="single-upload">
        <el-form ref="ruleForm"
            :model="ruleForm"
            label-position="left"
            label-width="135px"
            size="small"
            :disabled="isLoading">
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="Order Id" prop="orderId" :rules="{ required: true }">
                        <el-input v-model="ruleForm.orderId"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="Shipped Date" prop="shippedDate">
                        <el-input v-model="ruleForm.shippedDate"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
            <el-row :gutter="20" v-for="(item, index) in ruleForm.skus" :key="`${item.id}-sku`">
                <el-col :span="11">
                    <el-form-item label="B2B SKU#" :prop="'skus.' + index + '.sku'" :rules="{ required: true, message: 'SKU is required!' }">
                        <el-input v-model="item.sku" placeholder="Product code on B2B Platform"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="Ship To Qty" prop="qty">
                        <el-input v-model="item.qty" placeholder="Dispatch Quantity"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2">
                    <div v-if="index === ruleForm.skus.length - 1"
                        class="row-btn plus"
                        @click="doAddSku">
                    </div>
                    <div v-else
                        class="row-btn minus"
                        @click="doDelete(index)">
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="0" class="deal-row">
                <el-col :span="7">
                    <el-form-item label="Ship To Address" prop="fullName" :rules="{ required: true }">
                        <el-input v-model="ruleForm.fullName" placeholder="Full name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="email">
                        <el-input v-model="ruleForm.email" placeholder="Email"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="phone">
                        <el-input v-model="ruleForm.phone" placeholder="Phone"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="address">
                        <el-input v-model="ruleForm.address" placeholder="Shipping Address"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
            <el-row :gutter="0" class="deal-row">
                <el-col :span="7">
                    <el-form-item prop="postalCode">
                        <el-input v-model="ruleForm.postalCode" placeholder="Postal Code"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="city">
                        <el-input v-model="ruleForm.city" placeholder="City"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="state">
                        <el-input v-model="ruleForm.state" placeholder="State"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item prop="country">
                        <el-input v-model="ruleForm.country" placeholder="Country"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
            <el-row :gutter="0">
                <el-form-item>
                    <div class="btn-group">
                        <el-button type="primary" :loading="isLoading" @click="doUpload">Upload</el-button>
                        <el-button type="primary" plain @click="doReset">Reset</el-button>
                    </div>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: ['item'],
    data () {
        return {
            isLoading: false,
            ruleForm: {
                orderId: '',
                shippedDate: '',
                skus: [{ sku: '', qty: '' }],
                fullName: '',
                email: '',
                phone: '',
                address: '',
                postalCode: '',
                city: '',
                state: '',
                country: ''
            }
        }
    },
    methods: {
        // 新增sku
        doAddSku () {
            this.ruleForm.skus.push({
                id: `${new Date().getTime()}`,
                sku: '',
                qty: ''
            })
        },
        // 删除
        doDelete (index) {
            this.ruleForm.skus.splice(index, 1)
        },
        // 提交
        doUpload () {
            // 上传
        },
        // 重置
        doReset () {
            this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
        }
    }
}
</script>

<style lang="scss" scoped>
.single-upload {
    padding: 20px;
    /deep/ .el-form-item__label {
        text-align: right;
    }
    .deal-row {
        /deep/ .el-input{
            width: 100%;
        }
        /deep/ .el-col:not(:first-of-type) .el-form-item__content {
            margin-left: 33px !important;
        }
    }
    .row-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: #FFF0E9;
        margin: 4px 0;
        position: relative;
        cursor: pointer;
        transition: opacity .3s ease-in;
        &:hover {
            opacity: .6;
            transition: opacity .3s ease-in;
        }
        &.plus {
            &::after {
                position: absolute;
                content: ' ';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 10px;
                height: 2px;
                background: #FF6C23;
            }
            &::before {
                position: absolute;
                content: ' ';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 2px;
                height: 10px;
                background: #FF6C23;
            }
        }
        &.minus {
            &::after {
                position: absolute;
                content: ' ';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 10px;
                height: 2px;
                background: #FF6C23;
            }
        }
    }
}
</style>
