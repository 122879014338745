<template>
    <div class="content">
        <div class="btn-groups">
            <el-button type="primary" size="small">Upload</el-button>
            <el-button size="small">Download Template</el-button>
            <el-button size="small">Instructions</el-button>
            <el-button size="small">Shipping Information</el-button>
        </div>
        <div class="progress-wrap">
            <el-progress class="progress"
                :stroke-width="12"
                :percentage="percentage"
                :show-text="false"
                :color="getColor"
                :status="progressStatus">
            </el-progress>
            <div style="margin-top: 5px;" :style="`color: ${getColor}`">
                <template v-if="progressStatus==='success'">Uploading {{ percentage }}%</template>
                <template v-if="progressStatus==='exception'">No detail found.</template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],
    data () {
        return {
            percentage: 65,
            progressStatus: 'success'
        }
    },
    computed: {
        getColor () {
            const status = {
                loading: '#1890FF',
                success: '#4DB519',
                exception: '#FF4D4F'
            }
            return status[this.progressStatus] || status.loading
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-wrap {
    margin-top: 20px;
}
</style>
