<template>
    <div class="order-batch__upload">
        <el-collapse class="collapse" v-model="active" accordion>
            <el-collapse-item v-for="item in collapseItems"
                :key="item.name"
                :name="item.name">
                <div class="title-wrap" slot="title">
                    <rap-image class="icon" :src="item.icon"></rap-image>
                    <span class="title">{{ item.title }}</span>
                    <span class="text">{{ item.text }}r</span>
                </div>
                <component :is="item.component" :item="item"></component>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import BatchUpload from './components/upload/batch-upload'
import SingleUpload from './components/upload/single-upload'

export default {
    components: {
        BatchUpload,
        SingleUpload
    },
    data () {
        return {
            active: '',
            collapseItems: [
                {
                    name: 'batch',
                    icon: require('@/assets/rap/order/order-batch-img.svg'),
                    text: 'Upload multiple orders together',
                    title: 'Batch Order Upload',
                    component: BatchUpload
                },
                {
                    name: 'record',
                    icon: require('@/assets/rap/order/order-single-img.svg'),
                    text: 'A single order uploaded',
                    title: 'Single record upload',
                    component: SingleUpload
                }
            ],
            percentage: 65,
            progressStatus: 'success'
        }
    }
}
</script>

<style lang="scss">
.order-batch__upload {
    margin: 20px 0;
    .collapse {
        border: none;
        .el-collapse-item {
            border: 1px solid #dedede;
            margin-bottom: 10px;
            border-radius: 2px;
            transition: all .3s ease-in-out;
            /deep/ .el-collapse-item__header{
                padding: 0 5px 0 20px;
                border-bottom-width: 0;
                transition: all .3s ease-in-out;
            }
            /deep/ .el-collapse-item__wrap {
                border-bottom-width: 0;
                .el-collapse-item__content {
                    padding-bottom: 0;
                }
            }
            .title-wrap {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .icon {
                    width: 28px;
                    height: 28px;
                    margin-right: 10px;
                }
                .title {
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    color: #000000;
                    line-height: 22px;
                    margin-right: 15px;
                }
                .text {
                    font-size: 13px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: rgba($color: #000000, $alpha: .4);
                }
            }
            .content {
                padding: 15px 24px;
            }
            &.is-active {
                /deep/ .el-collapse-item__header {
                    background: #f2f2f2;
                    border-bottom: 1px solid #dedede;
                    transition: all .3s ease-in-out;
                }
                transition: all .3s ease-in-out;
            }
        }
    }
}
</style>
